import { Container } from "@app/components/shared/appearance/page";
import { AgGridTable } from "@app/components/shared/AgGridTable";
import React, { FC, useContext, useMemo } from "react";
import { GenericRecord } from "../../../../scripts/models";
import styles from "@dms/modules/DocumentTableModule/DocumentTable.module.scss";
import { GridOptions, ICellRendererParams, IHeaderParams, RowSelectedEvent } from "ag-grid-community";
import type { ColDef, ColGroupDef } from "ag-grid-community/dist/types/core/entities/colDef";
import { GenericRecordColumns } from "@app/columns/ColumnConfig";
import { GenericRecordProperties, TableProperties } from "../../../../scripts/core/Product";
import { ExportPreprocessor } from "../../../../scripts/exporters/ExportPreprocessor";
import { TableViewContext } from "../../../../scripts/context/tableViewContext/tableViewContext";
import { CompanyContext } from "../../../../scripts/context/CompanyContext";
import { FormattedMessage } from "react-intl";
import { Button, Flex, Popconfirm } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

type CellActionsProps = { onEdit: () => void; onDelete: () => void };

type Props = {
    templates: GenericRecord[];
    actions?: {
        onEdit: (v: GenericRecord) => void;
        onDelete: (v: GenericRecord) => void;
    };
    onSelect?: (v: GenericRecord) => void;
};

const HeaderActions: React.FC<IHeaderParams<GenericRecord>> = () => {
    return (
        <div>
            <FormattedMessage id={"app.fields.actions"} />
        </div>
    );
};

const CellActions: React.FC<ICellRendererParams<GenericRecord> & CellActionsProps> = ({ onEdit, onDelete }) => {
    return (
        <Flex gap={5} align={"center"} style={{ height: "100%" }}>
            <Button icon={<EditOutlined />} shape={"circle"} onClick={onEdit} />
            <Popconfirm
                onConfirm={onDelete}
                title={<FormattedMessage id="app.confirmation.header" />}
                okText={<FormattedMessage id="app.button.confirm" />}
                cancelText={<FormattedMessage id="app.button.cancel" />}
            >
                <Button icon={<DeleteOutlined />} shape={"circle"} />
            </Popconfirm>
        </Flex>
    );
};

export const TemplatesTable: FC<Props> = ({ templates, actions, onSelect }) => {
    const { yearConfig } = useContext(CompanyContext);
    const { product } = useContext(TableViewContext);
    const gridOptions = useMemo(
        () => ({
            defaultColDef: {
                filter: "agTextColumnFilter",
                resizable: true,
                sortable: true,
                minWidth: 50,
                enableCellChangeFlash: true,
                suppressHeaderFilterButton: true,
                cellClass: styles.binaleCell,
                cellRenderer: ({ value }: ICellRendererParams<GenericRecord>) => {
                    return <div>{value ?? ""}</div>;
                },
            },
            // pagination: true,
            // paginationPageSize: 50,
            // paginationPageSizeSelector: [50, 100, 1000],
            // headerHeight: 50,
            // autoSizePadding: 20,
            // rowHeight: 40,
            rowGroupPanelShow: "onlyWhenGrouping",
            sortingOrder: ["asc", "desc", null],
            rowSelection: "single",
            suppressRowClickSelection: true,
            suppressAggFuncInHeader: true,
            alwaysShowHorizontalScroll: true,
            alwaysShowVerticalScroll: true,
            rowMultiSelectWithClick: true,
            debounceVerticalScrollbar: true,
            suppressMultiSort: true,
            rowBuffer: 30,
            onRowSelected: (event: RowSelectedEvent<GenericRecord>) => {
                const isSelected = event.node.isSelected();
                if (onSelect) {
                    onSelect(isSelected ? event.data : undefined);
                }
            },
            // onSelectionChanged(event: SelectionChangedEvent<GenericRecord>) {
            //     console.log(event);
            // },
            // loadingOverlayComponent: TableLoader,
        }),
        [onSelect]
    ) as GridOptions<GenericRecord>;

    const columns: (ColDef<GenericRecord> | ColGroupDef<GenericRecord>)[] = useMemo(() => {
        const defs: (ColDef<GenericRecord> | ColGroupDef<GenericRecord>)[] = [];
        if (!product) {
            return defs;
        }
        const cols = new GenericRecordColumns(product, yearConfig);
        const excludeColumns = new Set([
            TableProperties.ComputedNr,
            TableProperties.ControlAction,
            TableProperties.ControlLog,
            TableProperties.ComputedModul,
            GenericRecordProperties.RecordDatum,
            GenericRecordProperties.RecordPeriod,
            GenericRecordProperties.ComputedOffen,
            GenericRecordProperties.ComputedZA,
            GenericRecordProperties.ComputedStatus,
            GenericRecordProperties.ControlBinding,
            GenericRecordProperties.RecordReview,
            GenericRecordProperties.ComputedSaldo,
            GenericRecordProperties.RecordFalligkeit,
            GenericRecordProperties.ComputedSHInfo,
            GenericRecordProperties.RecordPicture,
        ]);
        if (onSelect) {
            defs.push({
                field: "key",
                pinned: "left",
                headerName: "",
                width: 50,
                filter: false,
                sortable: false,
                resizable: false,
                suppressMovable: true,
                suppressNavigable: true,
                lockPosition: true,
                headerCheckboxSelection: false,
                headerCheckboxSelectionCurrentPageOnly: false,
                checkboxSelection: true,
            });
        }
        cols.getColumnConfig()
            .filter(v => !excludeColumns.has(v.key))
            .forEach(col => {
                return defs.push({
                    headerValueGetter: () => ExportPreprocessor.nodeToString(col.header),
                    valueGetter: v => {
                        const res = col.getter({ item: v.data, key: v.data.key, children: [], extra: {} });
                        // console.log(col.key, res);
                        return res;
                    },
                    width: col.width + 20,
                    filter: false,
                });
            });
        if (actions) {
            defs.push({
                headerComponent: HeaderActions,
                cellRenderer: CellActions,
                cellRendererParams: (params: ICellRendererParams<GenericRecord>): CellActionsProps => ({
                    onEdit: () => actions.onEdit(params.data),
                    onDelete: () => actions.onDelete(params.data),
                }),
                field: "actions" as any,
                floatingFilter: false,
                resizable: false,
                suppressMovable: true,
                suppressNavigable: true,
                width: 100,
                pinned: "right",
                sortable: false,
                lockPosition: true,
            });
        }
        return defs;
    }, [product, yearConfig, actions, onSelect]);

    return (
        <Container absolute>
            {(w, h) => (
                <AgGridTable
                    rowData={templates}
                    wrapperStyles={{ height: h, width: w }}
                    columnDefs={columns}
                    gridOptions={gridOptions}
                />
            )}
        </Container>
    );
};
