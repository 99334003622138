import React, { FC, useContext } from "react";
import { Segmented } from "antd";
import { InvoiceFormInputTranslate, TInvoiceTotalView } from "@inv/types";
import { useIntl } from "react-intl";
import {
    InvoiceFormContext,
    InvoiceFormControlContext
} from "@inv/modules/CreateInvoicingModule/context/InvoiceFormContext";

export const GrossNetSwitch: FC = () => {
    const intl = useIntl();
    const { invoiceTotalView } = useContext(InvoiceFormContext);
    const { setInvoiceTotalView } = useContext(InvoiceFormControlContext);

    return (
        <Segmented
            options={[
                {
                    value: "Gross",
                    label: intl.formatMessage({ id: InvoiceFormInputTranslate.BRUTTO }),
                },
                {
                    value: "Nett",
                    label: intl.formatMessage({ id: InvoiceFormInputTranslate.NETTO }),
                },
            ]}
            defaultValue={invoiceTotalView}
            onChange={(value: TInvoiceTotalView) => {
                setInvoiceTotalView(value);
            }}
        />
    );
};
