import React, { useContext, useEffect } from "react";
import {
    ArrowLeftOutlined,
    ArrowRightOutlined,
    CheckSquareOutlined,
    DownloadOutlined,
    LinkOutlined
} from "@ant-design/icons";
import { Badge, Button, InputRef, message, Modal, Progress, Segmented, Space, Typography } from "antd";
import { Base, GQL, KontoNumUtils } from "@binale-tech/shared";
import { FormattedMessage, useIntl } from "react-intl";

import pLimit from "p-limit";
import { Category, GenericRecord } from "scripts/models";
import { CategoryCreditorMode, CategoryCreditorModes } from "scripts/core/Product";
import { CombinedCombobox } from "appearance/components/shared/form/baseComponents/comboboxes/CombinedCombobox";
import { CompanyContext, YearPeriodContext } from "scripts/context/CompanyContext";
import { PaymentsControlContext } from "scripts/context/PaymentsProvider";
import { GenericToolbarFilters } from "../../../../../../scripts/context/tableViewContext/tableViewFilters";
import { Layout } from "appearance/components/shared/Layout";
import { ReconciliationMapResult, useKAReconciliationMap } from "./useKAReconciliationMap";
import { TableColumns } from "appearance/columns/ColumnConfig";
import {
    TableFiltersContext,
    TableFiltersControlContext,
    TableViewContext
} from "scripts/context/tableViewContext/tableViewContext";
import { ToolbarComponents } from "appearance/components/toolbar/components";
import { useDocumentTitle } from "scripts/infrastructure/hooks/useDocumentTitle";
import { useIsMounted } from "scripts/infrastructure/hooks";
import { useKAToolbarNumNameList } from "./useKAToolbarNumNameList";

interface Props {
    tableColumns?: TableColumns<any>;
    disabledTableColumns: string[];
    onExportClick: () => void;
    kontoMode: CategoryCreditorMode;
    onKontoModeChange: (mode: CategoryCreditorMode) => void;
    displayRecords: GenericRecord[];
    onSelectReconciliationRecords: (ids: Set<string>) => void;
}

const accountOptions = [
    {
        value: CategoryCreditorModes.CAT,
        label: <FormattedMessage id="app.titles.category.pl" />,
    },
    {
        value: CategoryCreditorModes.DEB,
        label: <FormattedMessage id="app.titles.debitor.pl" />,
    },
    {
        value: CategoryCreditorModes.CRED,
        label: <FormattedMessage id="app.titles.creditor.pl" />,
    },
];
type ModalProps = {
    open: boolean;
    onCancel: () => void;
    reconciliationInput: ReconciliationMapResult;
};
const ReconciliationModal: React.FC<ModalProps> = ({ open, onCancel, reconciliationInput }) => {
    const { onPaymentGql } = React.useContext(PaymentsControlContext);
    const [processingIdx, setProcessingIdx] = React.useState<number>();

    const inputs: GQL.IPaymentProtoInput[] = [];
    reconciliationInput?.autoReconciliationRecords.forEach(item => {
        item.paymentRepresentationRecords.forEach(representationRecord => {
            inputs.push({
                sourceRecordKey: item.sourceRecord.key,
                strategy: GQL.IPaymentProtoStrategy.Representation,
                strategyRepresentation: {
                    discountAmount: 0,
                    representationRecordKey: representationRecord.key,
                },
            });
        });
    });
    const totalCount = inputs.length;

    React.useEffect(() => {
        setProcessingIdx(undefined);
    }, [open]);
    const startProgress = async () => {
        setProcessingIdx(0);
        const limit = pLimit(5);
        const input = inputs.map(input =>
            limit(() =>
                onPaymentGql({ payment: input }, true)
                    .catch(e => message.error(e.message))
                    .finally(() => setProcessingIdx(i => i++))
            )
        );
        await Promise.all(input).finally(() => setProcessingIdx(totalCount));
        await new Promise(r => setTimeout(r, 1000));
        setProcessingIdx(undefined);
        onCancel();
    };
    const step = 100 / totalCount;
    const progress = processingIdx === totalCount ? 100 : Math.floor((processingIdx ?? 0) * step);
    return (
        <Modal
            open={open}
            onCancel={onCancel}
            cancelButtonProps={{ disabled: Number.isFinite(processingIdx) }}
            okButtonProps={{ disabled: Number.isFinite(processingIdx) }}
            title={<FormattedMessage id="app.titles.reconciliation" />}
            onOk={startProgress}
            closable={false}
            maskClosable={false}
            destroyOnClose
        >
            {Number.isFinite(processingIdx) ? (
                <div>
                    <p>
                        {processingIdx} / {totalCount}
                    </p>
                    <Progress percent={progress} status={progress < 100 ? "active" : undefined} />
                </div>
            ) : (
                <p>Press OK to start (total {inputs.length})</p>
            )}
        </Modal>
    );
};

const KontoAnsichtToolbar: React.FC<Props> = props => {
    const isMounted = useIsMounted();
    const intl = useIntl();
    const setDocumentTitle = useDocumentTitle();
    const { yearConfig, companyGQL } = useContext(CompanyContext);
    const { view, moduleRecords: records } = useContext(TableViewContext);
    const { numNameList } = useKAToolbarNumNameList({ records });
    const reconciliationData = useKAReconciliationMap({
        displayRecords: props.displayRecords,
    });
    const { year, period, onChangeYear, onChangePeriod } = useContext(YearPeriodContext);
    const { filters } = useContext(TableFiltersContext);
    const { setFilter, resetFilters } = useContext(TableFiltersControlContext);
    const comboboxRef = React.createRef<InputRef>();
    const [searchFilter, setSearchFilter] = React.useState("");
    const [reconciliationModalInput, setReconciliationModalInput] = React.useState<ReconciliationMapResult>();

    const { onKontoModeChange, kontoMode: accountType } = props;
    const { current: combobox } = comboboxRef;
    const kontoExt = yearConfig?.kontoExt ?? 0;
    const accountingYears = companyGQL?.accountingYears || [];
    const categoryCreditor = filters.get(GenericToolbarFilters.Konto)?.value;
    const showReconciliationButton = [CategoryCreditorModes.CRED, CategoryCreditorModes.DEB].includes(accountType);

    useEffect(() => {
        if (combobox) {
            combobox.focus();
        }
    }, [combobox]);

    const list = React.useMemo(() => {
        return numNameList.filter(item => {
            if (accountType === CategoryCreditorModes.DEB && KontoNumUtils.isDebitor({ extNum: item }, kontoExt)) {
                return true;
            }
            if (accountType === CategoryCreditorModes.CRED && KontoNumUtils.isCreditor({ extNum: item }, kontoExt)) {
                return true;
            }
            if (accountType === CategoryCreditorModes.CAT && item instanceof Category) {
                return true;
            }
            return false;
        });
    }, [numNameList, accountType, kontoExt]);

    const onFilterKontoChange = React.useCallback(
        (value: Base.IExtNum | undefined) => {
            const isValueDefined = Boolean(value);
            if (isValueDefined && typeof value !== "object") {
                return;
            }
            setDocumentTitle(() => {
                const typeIntl = intl.formatMessage({ id: `app.titles.${accountType}` });
                const defaultPageIntl = intl.formatMessage({ id: "app.titles.KA" });

                return isValueDefined ? `${typeIntl} ${value.getExtNumPrint(kontoExt)}` : defaultPageIntl;
            });

            setFilter(GenericToolbarFilters.Konto, {
                // filtering is handled in the KontenAnsichtView.postProcessTableItems
                predicate: () => true,
                value,
            });
        },
        [accountType, intl, kontoExt, setDocumentTitle, setFilter]
    );

    useEffect(() => {
        if (isMounted()) {
            onFilterKontoChange(undefined);
        }
    }, [accountType, isMounted, onFilterKontoChange]);
    const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Escape") {
            onFilterKontoChange(undefined);
            setSearchFilter("");
            resetFilters();
        }
    };

    const onShift = (v: -1 | 1) => {
        if (!list.length) {
            return;
        }
        if (!categoryCreditor) {
            onFilterKontoChange(list[0]);
            return;
        }

        const idx = list.findIndex(cc => cc.equalsTo(categoryCreditor));
        if (idx === -1) {
            onFilterKontoChange(undefined);
            return;
        }
        let newIdx = idx + v;
        if (newIdx < 0) {
            newIdx = list.length - 1;
        }
        if (newIdx >= list.length) {
            newIdx = 0;
        }
        onFilterKontoChange(list[newIdx]);
    };

    const handleRadioChange: React.ComponentProps<typeof Segmented>["onChange"] = (value: CategoryCreditorMode) => {
        onFilterKontoChange(undefined);
        onKontoModeChange(value);
        setDocumentTitle(
            intl.formatMessage({
                id: "app.titles.KA",
            })
        );
    };
    return (
        <div className="GenericToolbar" onKeyDown={onKeyDown}>
            <Layout style={{ alignItems: "center" }}>
                <ToolbarComponents.DateSection
                    period={period}
                    year={year}
                    years={accountingYears}
                    onPeriodChange={onChangePeriod}
                    onYearChange={onChangeYear}
                    withAll
                />
                <Segmented value={accountType} onChange={handleRadioChange} options={accountOptions} />

                <Space.Compact>
                    <CombinedCombobox
                        placeholder={<FormattedMessage id="app.global.not_selected" />}
                        inputRef={comboboxRef}
                        allowClear
                        value={categoryCreditor}
                        items={list}
                        onChange={onFilterKontoChange}
                        style={{ width: 150 }}
                        showAggregationCategories
                        notFoundContent={<FormattedMessage id="app.messages.no_elements_found" tagName="span" />}
                        inputProps={{
                            "data-testid": "category-creditor",
                        }}
                    />
                    <Button icon={<ArrowLeftOutlined />} onClick={() => onShift(-1)} />
                    <Button icon={<ArrowRightOutlined />} onClick={() => onShift(1)} />
                </Space.Compact>
                {categoryCreditor && (
                    <Typography.Text
                        style={{
                            minWidth: 50,
                            maxWidth: "calc(100vw - 1165px)",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            display: "block",
                            overflow: "hidden",
                        }}
                    >
                        {categoryCreditor.name}
                    </Typography.Text>
                )}
                <div style={{ flexGrow: 1 }} />
                {showReconciliationButton && (
                    <Space.Compact>
                        <Button
                            disabled={!reconciliationData.possiblePayments}
                            onClick={() => {
                                const ids = new Set<string>();
                                Array.from(reconciliationData.autoReconciliationRecords.values()).forEach(v => {
                                    ids.add(v.sourceRecord.key);
                                    v.paymentRepresentationRecords.forEach(({ key }) => ids.add(key));
                                });
                                props.onSelectReconciliationRecords(ids);
                            }}
                            icon={<CheckSquareOutlined />}
                        />
                        <Badge
                            count={reconciliationData.possiblePayments}
                            style={{ zIndex: 1000 }}
                            overflowCount={1000}
                        >
                            <Button
                                disabled={!reconciliationData.possiblePayments}
                                icon={<LinkOutlined />}
                                onClick={() => setReconciliationModalInput(reconciliationData)}
                            >
                                {" "}
                                <FormattedMessage id="app.titles.reconciliation" />
                            </Button>
                        </Badge>
                    </Space.Compact>
                )}
                <ToolbarComponents.SearchInput
                    onSearch={value => {
                        setFilter(GenericToolbarFilters.TextSearch, {
                            // filtering is handled in the KontenAnsichtView.postProcessTableItems
                            predicate: () => true,
                            value,
                        });
                    }}
                    value={searchFilter}
                    onChange={setSearchFilter}
                />
                <Button onClick={props.onExportClick} icon={<DownloadOutlined />} shape="circle" />
                <ToolbarComponents.AnsichtBtn
                    tableColumns={props.tableColumns}
                    view={view}
                    disabledOptions={props.disabledTableColumns}
                />
            </Layout>
            <ReconciliationModal
                reconciliationInput={reconciliationModalInput}
                open={Boolean(reconciliationModalInput)}
                onCancel={() => setReconciliationModalInput(undefined)}
            />
        </div>
    );
};

export default React.memo(KontoAnsichtToolbar);
