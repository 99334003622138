import React, { FC } from "react";
import { Col, Form, Row } from "antd";
import { StringInput } from "@dms/components/DocumentForm/DocumentInputs/StringInput";
import { InvoiceFormBlocksTranslate, InvoiceFormInputTranslate, InvoiceInputs } from "@inv/types";
import { InvoiceFormItemWrapper } from "@inv/components/CreateInvoicingForm/components/InvoiceFormItemWrapper";
import { useIntl } from "react-intl";
import { TextAreaInput } from "@dms/components/DocumentForm/DocumentInputs/TextArea";

export const FooterBlock: FC = () => {
    const intl = useIntl();

    return (
        <InvoiceFormItemWrapper title={intl.formatMessage({ id: InvoiceFormBlocksTranslate.FOOTER_SECTION })}>
            <Row>
                <Col span={24}>
                    <Form.Item name={InvoiceInputs.PAYMENT_TERM}>
                        <StringInput placeholder={intl.formatMessage({ id: InvoiceFormInputTranslate.PAYMENT_TERM })} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name={InvoiceInputs.DESCRIPTION}>
                        <TextAreaInput
                            placeholder={intl.formatMessage({ id: InvoiceFormInputTranslate.DESCRIPTION })}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </InvoiceFormItemWrapper>
    );
};
