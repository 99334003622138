import React, { FC, useContext, useMemo, useRef } from "react";
import { gridOptions } from "@banks/modules/PreviewParseDataModule/gridOptions";
import { TransactionsTable } from "@banks/components";
import { CustomTableHeader } from "@banks/modules/PreviewParseDataModule/components/CustomTableHeader";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { RequiredTableCols } from "@app/components/shared/AgGridTable/constants/tableCols";
import { PreviewParseDataContext } from "@banks/modules/PreviewParseDataModule/context/PreviewParseDataContext";

const PREPARSE_TABLE_CONTAINER_HEIGHT = "calc(100vh - 300px)";

export const PreviewParseDataTable: FC = () => {
    const { previewParseResult } = useContext(PreviewParseDataContext);

    const tableRef = useRef<AgGridReact>(null);

    const tableColumns: Readonly<ColDef>[] = useMemo(() => {
        const col = Object.keys(previewParseResult[0] || {})
            .filter(rk => rk !== "csvLine")
            .map(key => {
                return {
                    headerName: key,
                    headerTooltip: key,
                    field: key,
                    width: 200,
                    autoHeaderHeight: true,
                    sortable: false,
                    filter: false,
                };
            });

        return [
            {
                headerName: "No",
                field: RequiredTableCols.ROW_NUMBER,
                valueGetter: params => params.node.rowIndex + 1,
                width: 60,
                resizable: false,
                filter: false,
                suppressHeaderMenuButton: true,
                suppressMovable: true,
                lockPosition: true,
                suppressNavigable: true,
            },
            ...col,
        ];
    }, [previewParseResult]);

    const components = useMemo<{
        [p: string]: any;
    }>(() => {
        return {
            agColumnHeader: CustomTableHeader,
        };
    }, []);

    return (
        <TransactionsTable
            ref={tableRef}
            tableKey={"preview-parse-bank-data"}
            rowData={previewParseResult}
            gridOptions={gridOptions}
            components={components}
            tableColumns={tableColumns}
            viewHeight={PREPARSE_TABLE_CONTAINER_HEIGHT}
        />
    );
};
