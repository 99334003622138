import { GQL } from "@binale-tech/shared";
import React, {
    type Dispatch,
    type FC,
    type PropsWithChildren,
    type SetStateAction,
    useContext,
    useEffect,
    useState
} from "react";

import {
    DmsDefaultSubType,
    DmsType,
    IDocumentEnriched,
    TBindSubType,
    TReducedFileInfo,
    TUploaderFileInfo,
    TUploadFiles
} from "@dms/types";

import { fileDataReducer } from "../helpers/fileDataReducer";
import { DmsDataContext } from "@dms/types/ContextTypes";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";
import { ALL_DOCUMENTS } from "@dms/configs/constants";
import { useBindSubtype } from "@dms/hooks/useBindSubtype";

type TProps = {
    onFilesAdd?: (arg: string[]) => void;
    productData?: { productKey: GQL.IProductKey; selectedRecordGroup: string };
    documentType?: string[];
};

interface TValue {
    addedFilesCount: number;
    setAddedFilesCount: Dispatch<SetStateAction<number>>;
    allFilesInfo: TUploaderFileInfo[] | [];
    setAllFilesInfo: Dispatch<SetStateAction<TUploaderFileInfo[] | []>>;
    reducedFilesInfo: TReducedFileInfo | undefined;
    setReducedFilesInfo: Dispatch<SetStateAction<TReducedFileInfo | undefined>>;
    uniqFiles: TUploadFiles | undefined;
    setUniqFiles: Dispatch<SetStateAction<TUploadFiles | undefined>>;
    notUniqFiles: TReducedFileInfo | undefined;
    setNotUniqFiles: Dispatch<SetStateAction<TReducedFileInfo | undefined>>;
    uploadedFiles: TUploadFiles | undefined;
    setUploadedFiles: Dispatch<SetStateAction<TUploadFiles | undefined>>;
    uploadingFiles: TUploadFiles | undefined;
    setUploadingFiles: Dispatch<SetStateAction<TUploadFiles | undefined>>;
    hadUploadedFiles: Record<string, IDocumentEnriched> | undefined;
    setHadUploadedFiles: Dispatch<SetStateAction<Record<string, IDocumentEnriched> | undefined>>;
    removedFiles: TUploaderFileInfo[] | undefined;
    setRemovedFiles: Dispatch<SetStateAction<TUploaderFileInfo[] | undefined>>;
    isDisabled: boolean;
    setIsDisabled: Dispatch<SetStateAction<boolean>>;
    productKey?: GQL.IProductKey;
    allUploaderFilesKeys: Set<string>;
    isDndFile: boolean;
    setIsDndFile: Dispatch<SetStateAction<boolean>>;
    documentType?: { type: DmsType | typeof ALL_DOCUMENTS; subType?: string };
}

/**
 Context for File Uploader state
 */
export const FileUploaderContext = React.createContext({} as TValue);

/**
 Context Provider for File Uploader state
 */
export const FileUploaderProvider: FC<PropsWithChildren & TProps> = ({
    children,
    onFilesAdd,
    productData,
    documentType,
}) => {
    const [type, setType] = useState<{ type: DmsType | typeof ALL_DOCUMENTS; subType?: string }>({
        type: DmsType.new_documents,
    });
    const [addedFilesCount, setAddedFilesCount] = useState<number>(0);

    const [allFilesInfo, setAllFilesInfo] = useState<TUploaderFileInfo[]>([]);
    const [reducedFilesInfo, setReducedFilesInfo] = useState<TReducedFileInfo>();
    const [uniqFiles, setUniqFiles] = useState<TUploadFiles>();
    const [notUniqFiles, setNotUniqFiles] = useState<TReducedFileInfo>();
    const [uploadedFiles, setUploadedFiles] = useState<TUploadFiles>();
    const [uploadingFiles, setUploadingFiles] = useState<TUploadFiles>();
    const [hadUploadedFiles, setHadUploadedFiles] = useState<Record<string, IDocumentEnriched>>();
    const [removedFiles, setRemovedFiles] = useState<TUploaderFileInfo[]>();
    const [allUploaderFilesKeys, setAllUploaderFilesKeys] = useState<Set<string>>(new Set());
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [isDndFile, setIsDndFile] = useState<boolean>(false);

    const getSubTypeByBoundId = useBindSubtype();

    const { documentsKV } = useContext(DmsDataContext);

    useEffect(() => {
        if (documentType && documentType.length) {
            const { type, subType } = DmsUtils.getActiveTypeAndSubType(documentType);
            let checkedSubType;
            if (subType) {
                const isDefaultSubtype =
                    subType.includes(DmsDefaultSubType.all_subTypes) || subType.includes(DmsDefaultSubType.no_subTypes);
                if (!isDefaultSubtype) {
                    checkedSubType = subType;
                }
            }

            return setType({ type, subType: checkedSubType });
        }

        if (productData) {
            const { productKey, selectedRecordGroup } = productData;
            let type: DmsType;
            if (productKey in DmsType) {
                type = productKey as unknown as DmsType;
            } else if (productKey === GQL.IProductKey.ErA) {
                type = GQL.IProductKey.Er as unknown as DmsType;
            } else {
                return;
            }

            const subType = getSubTypeByBoundId(selectedRecordGroup, type as TBindSubType);

            setType({ type, subType });
        }
    }, [productData, documentType]);

    useEffect(() => {
        if (hadUploadedFiles) {
            Object.keys(hadUploadedFiles).forEach(key => {
                setAllUploaderFilesKeys(prevSet => {
                    prevSet.add(key);
                    return prevSet;
                });
            });
        }

        if (uploadedFiles) {
            Object.keys(uploadedFiles).forEach(key => {
                setAllUploaderFilesKeys(prevSet => {
                    prevSet.add(key);
                    return prevSet;
                });
            });
        }

        const uploadedFilesLength = uploadedFiles ? Object.keys(uploadedFiles).length : 0;
        const removedFilesLength = removedFiles ? removedFiles.length : 0;
        const length = uploadedFilesLength + removedFilesLength;

        if (addedFilesCount === length) {
            setIsDisabled(false);
            setAddedFilesCount(0);
            setAllFilesInfo([]);
            setReducedFilesInfo(undefined);
            setUniqFiles(undefined);
            setNotUniqFiles(undefined);
            setRemovedFiles(undefined);
        }
    }, [addedFilesCount, uploadedFiles, removedFiles, hadUploadedFiles]);

    useEffect(() => {
        if (!allFilesInfo || addedFilesCount !== allFilesInfo.length) {
            return;
        }

        const reduceFilesInfo = fileDataReducer([...allFilesInfo]);

        setReducedFilesInfo(reduceFilesInfo);
    }, [allFilesInfo]);

    useEffect(() => {
        if (!(reducedFilesInfo && Object.keys(reducedFilesInfo).length > 0)) {
            return;
        }

        Object.keys(reducedFilesInfo).forEach((hash: string) => {
            if (documentsKV && Object.keys(documentsKV).includes(hash)) {
                setHadUploadedFiles(prev => {
                    return {
                        ...prev,
                        [hash]: { ...documentsKV[hash] },
                    };
                });

                setRemovedFiles(prev => {
                    if (!prev) {
                        return reducedFilesInfo[hash];
                    }
                    return [...prev, ...reducedFilesInfo[hash]];
                });
                return;
            }

            if (reducedFilesInfo[hash].length > 1) {
                setNotUniqFiles(prev => {
                    return {
                        ...prev,
                        [hash]: reducedFilesInfo[hash].map(el => {
                            return {
                                ...el,
                                isUploaded: false,
                            };
                        }),
                    };
                });
                return;
            }

            setUniqFiles(prev => {
                return {
                    ...prev,
                    [hash]: reducedFilesInfo[hash].map(el => {
                        return {
                            ...el,
                            isUploaded: false,
                        };
                    })[0],
                };
            });
        });
    }, [reducedFilesInfo]);

    useEffect(() => {
        if (!uniqFiles) {
            return;
        }

        const uploadingFiles = {} as TUploadFiles;
        const uploadedFiles = {} as TUploadFiles;
        Object.keys(uniqFiles).forEach(el => {
            if (uniqFiles && uniqFiles[el].isUploaded) {
                return (uploadedFiles[el] = uniqFiles[el]);
            }
            return (uploadingFiles[el] = uniqFiles[el]);
        });

        setUploadedFiles(Object.keys(uploadedFiles).length > 0 ? uploadedFiles : undefined);
        setUploadingFiles(Object.keys(uploadingFiles).length > 0 ? uploadingFiles : undefined);
    }, [uniqFiles]);

    useEffect(() => {
        if (onFilesAdd) {
            onFilesAdd([...allUploaderFilesKeys]);
        }
    }, [allUploaderFilesKeys.size]);

    const value: TValue = {
        addedFilesCount,
        setAddedFilesCount,
        allFilesInfo,
        setAllFilesInfo,
        reducedFilesInfo,
        setReducedFilesInfo,
        uniqFiles,
        setUniqFiles,
        notUniqFiles,
        setNotUniqFiles,
        uploadedFiles,
        setUploadedFiles,
        hadUploadedFiles,
        setHadUploadedFiles,
        removedFiles,
        setRemovedFiles,
        isDisabled,
        setIsDisabled,
        uploadingFiles,
        setUploadingFiles,
        allUploaderFilesKeys,
        isDndFile,
        setIsDndFile,
        documentType: type,
    };

    return <FileUploaderContext.Provider value={value}>{children}</FileUploaderContext.Provider>;
};
