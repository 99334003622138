import React, {
    ContextType,
    type FC,
    type PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";

import { AppConfigUtils } from "@dms/scripts/utils/AppConfigUtils";
import { TFilterConfigItem, TTableCols } from "@dms/types";
import { DmsDataContext, DmsUserSettingsContext } from "@dms/types/ContextTypes";
import { CompanyContext } from "../../../scripts/context/CompanyContext";
import { UserContext } from "../../../scripts/context/UserProvider";
import ProgramSettings from "../../../scripts/models/ProgramSettings";

/**
 Context Provider for User config state
 */
export const DmsUserProvider: FC<PropsWithChildren> = ({ children }) => {
    const [columnsTableConfig, setColumnsTableConfig] = useState<
        ContextType<typeof DmsUserSettingsContext>["userConfig"]["columnsTableConfig"]
    >(new Map());
    const [filterConfig, setFilterConfig] = useState<
        ContextType<typeof DmsUserSettingsContext>["userConfig"]["filterConfig"]
    >(new Map());
    const [isTableSize, setIsTableSize] = useState<boolean>(false);
    const { documentTypes } = useContext(DmsDataContext);
    const { companyGQL } = useContext(CompanyContext);
    const { fireUser } = useContext(UserContext);

    // on mount/documentsType change set default filters
    useEffect(() => {
        const { defaultFilterConfig } = AppConfigUtils.getDefaultUserConfig(documentTypes);
        setFilterConfig(defaultFilterConfig);
    }, [documentTypes]);

    // on mount/documentsType re-fetch column config and merge it with default
    useEffect(() => {
        if (!companyGQL?.id || !fireUser?.uid || documentTypes?.length === 0) {
            return;
        }
        ProgramSettings.getDmsConfig({
            userId: fireUser.uid,
            companyId: companyGQL.id,
        }).then((res: Record<string, TTableCols[]>) => {
            const concatTableConf = AppConfigUtils.concatColumnConfig(documentTypes, res);
            setColumnsTableConfig(concatTableConf);
        });
    }, [companyGQL?.id, documentTypes, fireUser?.uid]);

    const persistTableColumns = useCallback(
        (config: Map<string, TTableCols[]>) => {
            const keys = {
                userId: fireUser.uid,
                companyId: companyGQL.id,
            };
            ProgramSettings.updateDmsConfig(keys, Object.fromEntries(config));
        },
        [companyGQL?.id, fireUser?.uid]
    );

    useEffect(() => {
        const handleResize = () => {
            setIsTableSize(document.documentElement.clientWidth < 1401);
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const onSetFilterConfig = useCallback((typeKey: string[], configData: TFilterConfigItem) => {
        const configKey = typeKey.toString();
        setFilterConfig(prev => {
            const typeConfig = prev.get(configKey);
            prev.set(configKey, { ...typeConfig, ...configData });
            return new Map(prev);
        });
    }, []);
    const onSetColumnsTableConfig = useCallback(
        (typeKey: string[], configTableData: TTableCols[]) => {
            const configKey = typeKey.toString();
            setColumnsTableConfig(prev => {
                const typeConfig = prev.get(configKey);
                prev.set(configKey, { ...typeConfig, ...configTableData });
                persistTableColumns(prev);
                return new Map(prev);
            });
        },
        [persistTableColumns]
    );
    const deleteTypeConfig = useCallback((typeKey: string[]) => {
        const configKey = typeKey.toString();
        setFilterConfig(prev => {
            prev.delete(configKey);
            return new Map(prev);
        });
        setColumnsTableConfig(prev => {
            prev.delete(configKey);
            return new Map(prev);
        });
    }, []);

    // useEffect(() => {
    //     console.log("filterConfig", filterConfig);
    // }, [filterConfig]);

    const value: ContextType<typeof DmsUserSettingsContext> = useMemo(
        () => ({
            userConfig: { columnsTableConfig, filterConfig },
            setColumnsTableConfig: onSetColumnsTableConfig,
            setFilterConfig: onSetFilterConfig,
            deleteTypeConfig,
            isTableSize,
        }),
        [columnsTableConfig, deleteTypeConfig, filterConfig, isTableSize, onSetColumnsTableConfig, onSetFilterConfig]
    );

    return <DmsUserSettingsContext.Provider value={value}>{children}</DmsUserSettingsContext.Provider>;
};
