import { Avatar, Flex, MenuProps, message, Tooltip } from "antd";
import { DocumentsApi } from "@dms/scripts/DocumentsApi/DocumentsApi";
import {
    ClearOutlined,
    CopyOutlined,
    DeleteOutlined,
    DownloadOutlined,
    EditOutlined,
    ExportOutlined
} from "@ant-design/icons";
import { FormattedMessage, useIntl } from "react-intl";
import { ProductAccessUtils } from "../../../scripts/models/utils/ProductAccessUtils";
import { ProductKey } from "../../../scripts/models/Product";
import { GQL } from "@binale-tech/shared";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { DmsAppContext, DmsAppControlContext, DmsDataContext } from "@dms/types/ContextTypes";
import { useGqlMutator } from "../../../scripts/graphql/useGqlMutator";
import { Link } from "react-router-dom";
import { PATH } from "@dms/configs/constants";
import { DmsType, IDocumentEnriched, ITableDocument } from "@dms/types";
import { BinaleError } from "../../../scripts/errors/errors";
import { TableRowColors } from "@dms/modules/DocumentTableModule/consts";
import { IRowNode } from "ag-grid-community";
import { RecordDataPopover } from "@dms/components/RecordDataTable/RecordDataPopover";
import { useDmsAccountingConverter } from "../../../scripts/models/converters/useDmsAccountingConverter";

type Props = {
    document: IDocumentEnriched;
    mode: "table" | "card";
    node: IRowNode<ITableDocument>;
};

export const useDropdownAction = ({ document, mode, node }: Props) => {
    const [importing, setImporting] = useState(false);
    const [title, setTitle] = useState(<FormattedMessage id="app.dms.copyId" />);

    const { documentTypes, dmsRecordsData } = useContext(DmsDataContext);

    const { deleteModeToggle, addFocusedRows } = useContext(DmsAppControlContext);
    const { selectRow, typeChangeModeToggle, setLoadDataSource } = useContext(DmsAppControlContext);
    const { selectedRowKeys } = useContext(DmsAppContext);
    const { recordsAssetsSet } = dmsRecordsData;

    const intl = useIntl();
    const mutator = useGqlMutator();
    const groupId = document ? DmsUtils.getGroupId(document.type, document.subType, documentTypes) : undefined;
    const { converter, actions: configActions } = useDmsAccountingConverter(document?.type as GQL.IProductKey, groupId);

    const dropdownItems: MenuProps["items"] = useMemo(() => {
        if (!document) {
            return null;
        }

        const setColorDocument = async (color: GQL.IDocumentColors | null, data: IDocumentEnriched) => {
            setLoadDataSource(true);
            const doc = { ...data, color };
            const dataRow: ITableDocument = {
                ...doc,
                isAttached: recordsAssetsSet.has(DocumentsApi.urlFormatter(data.fileUrl)) && (
                    <RecordDataPopover url={data.fileUrl} position={"table"} />
                ),
                isFocused: true,
            };

            try {
                await DocumentsApi.updateDocuments({
                    documents: [doc],
                    mutator,
                });
                node.setData(dataRow);
            } catch (error) {
                console.error("Failed to update document:", error);
            } finally {
                addFocusedRows([node.data.key]);
                setLoadDataSource(false);
            }
        };

        const colorItems = Object.values(GQL.IDocumentColors).map(el => ({
            key: el,
            label: (
                <Flex align={"center"} gap={5} onClick={() => setColorDocument(el, document)}>
                    <Avatar style={{ backgroundColor: TableRowColors[el as GQL.IDocumentColors] }} size={14} />
                    <FormattedMessage tagName="div" id={`app.button.color.${el}`} />
                </Flex>
            ),
        }));

        const handleDelete = async () => {
            if (document.type === DmsType.trash) {
                if (dmsRecordsData.recordsAssetsSet.has(DocumentsApi.urlFormatter(document.fileUrl))) {
                    return deleteModeToggle(true);
                }

                return DocumentsApi.removeDocuments([document], mutator);
            }

            await DocumentsApi.sendToTrash([document], mutator);
        };

        const checkedElement = (id: string): boolean => {
            return selectedRowKeys.includes(id);
        };

        const moveTo = (): void => {
            typeChangeModeToggle(true);
            if (!checkedElement(document.key)) {
                selectRow(document.key);
            }
        };

        const copyIdNumber = async (e: React.MouseEvent<HTMLElement>, id: string): Promise<void> => {
            e.stopPropagation();
            await navigator.clipboard.writeText(id);
            setTitle(<FormattedMessage id="app.dms.idCopied" />);
        };

        const resetTooltip = (open: boolean): void => {
            if (!open) {
                setTitle(<FormattedMessage id="app.dms.copyId" />);
            }
        };
        switch (mode) {
            case "table":
                return [
                    {
                        label: (
                            <div
                                onClick={() => {
                                    if (document) {
                                        DocumentsApi.downloadFile(document.fileUrl, DmsUtils.getDownloadName(document));
                                    }
                                }}
                            >
                                <DownloadOutlined /> <FormattedMessage id="app.button.download" />
                            </div>
                        ),
                        key: "0",
                    },
                    {
                        label: (
                            <div
                                onClick={async () => {
                                    if (document) {
                                        await handleDelete();
                                    }
                                }}
                            >
                                <DeleteOutlined /> <FormattedMessage id="app.button.delete" />
                            </div>
                        ),
                        key: "1",
                    },
                    { type: "divider" },
                    {
                        label: (
                            <div onClick={() => setColorDocument(null, document)}>
                                <ClearOutlined /> <FormattedMessage id="app.button.color.remove_color" />
                            </div>
                        ),

                        key: "removeColor",
                    },
                    ...colorItems,
                ];
            case "card":
                return [
                    {
                        label: (
                            <Link to={`/${PATH.DOCUMENTS}/${PATH.EDITING}/${document.key}`}>
                                <EditOutlined /> <FormattedMessage id="app.button.edit" />
                            </Link>
                        ),
                        key: "1",
                    },
                    {
                        label: (
                            <div onClick={moveTo}>
                                <ExportOutlined /> <FormattedMessage id="app.dms.moveTo" />
                            </div>
                        ),
                        key: "2",
                    },
                    {
                        label: (
                            <div
                                onClick={() => {
                                    DocumentsApi.downloadFile(document.fileUrl, DmsUtils.getDownloadName(document));
                                }}
                            >
                                <DownloadOutlined /> <FormattedMessage id="app.button.download" />
                            </div>
                        ),
                        key: "3",
                    },
                    {
                        label: (
                            <div onClick={e => copyIdNumber(e, document.key)}>
                                <Tooltip title={title} onOpenChange={resetTooltip}>
                                    <CopyOutlined /> <FormattedMessage id="app.dms.copyId" />
                                </Tooltip>
                            </div>
                        ),
                        key: "4",
                    },
                    {
                        label: (
                            <div onClick={handleDelete}>
                                <DeleteOutlined /> <FormattedMessage id="app.button.delete" />
                            </div>
                        ),
                        key: "5",
                    },
                ];
        }
    }, [
        document,
        mode,
        setLoadDataSource,
        recordsAssetsSet,
        mutator,
        node,
        addFocusedRows,
        dmsRecordsData.recordsAssetsSet,
        deleteModeToggle,
        selectedRowKeys,
        typeChangeModeToggle,
        selectRow,
        title,
    ]);

    const toGenericRecord = useCallback(async () => {
        if (!document || !configActions) {
            return;
        }

        setImporting(true);

        try {
            const record = converter.convertDocumentToRecord(document);
            if (!record) {
                throw new BinaleError("Unable to construct record", "app.error.message.incomplete_data");
            }
            await configActions.importMany([record], true).catch(e => message.warning(e.message));
            await message.success(intl.formatMessage({ id: "app.button.done" }));
        } catch (err) {
            if (err instanceof BinaleError) {
                message.warning(intl.formatMessage({ id: err.translationKey }));
            } else {
                message.error(err.message);
            }
        } finally {
            setImporting(false);
        }
    }, [configActions, converter, document, intl]);

    if (!dropdownItems) {
        return {};
    }

    const isAccountingExportDisabled = DmsUtils.isAccountingExportDisabled([document], document.type, groupId);

    return {
        dropdownItems: ProductAccessUtils.getAccountingKeys().has(document.type as ProductKey)
            ? [
                  {
                      label: (
                          <div>
                              <ExportOutlined /> FIBU
                          </div>
                      ),
                      disabled: importing || isAccountingExportDisabled,
                      key: "fibu",
                      onClick: toGenericRecord,
                  },
                  ...dropdownItems,
              ]
            : dropdownItems,
    };
};
