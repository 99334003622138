import React, { FC } from "react";
import { Flex } from "antd";
import { PreviewParseDataToolbar } from "@banks/modules/PreviewParseDataModule/components/PreviewParseDataToolbar";
import { PreviewParseDataContextProvider } from "@banks/modules/PreviewParseDataModule/context/PreviewParseDataContext";
import { PreviewParseDataTable } from "@banks/modules/PreviewParseDataModule/components/PreviewParseDataTable";
import { useLocation } from "react-router";

export const PreviewParseDataModule: FC = () => {
    const location = useLocation();

    return (
        <PreviewParseDataContextProvider
            initialBankData={location.state.bankToUpload}
            lastBankCreateId={location.state.lastBankCreateId}
            parseResult={location.state.parseResult}
        >
            <Flex vertical gap={20}>
                <PreviewParseDataToolbar />
                <PreviewParseDataTable />
            </Flex>
        </PreviewParseDataContextProvider>
    );
};
