import React, { FC } from "react";
import { InvoiceFormItemWrapper } from "@inv/components/CreateInvoicingForm/components/InvoiceFormItemWrapper";
import { Col, Form, Row } from "antd";
import { DocumentNumberInput } from "@dms/components/DocumentForm/DocumentInputs/DocumentNumberInput";
import { NumberFieldLabel } from "@dms/components/DocumentForm/DocumentFormFields/DocumentInvoiceNumberField";
import { InvoiceFormBlocksTranslate, InvoiceFormInputTranslate, InvoiceInputs } from "@inv/types";
import { DateInput } from "@dms/components/DocumentForm/DocumentInputs/DateInput";
import { DeliverySwitch } from "@inv/components/CreateInvoicingForm/components/DeliverySwitch";
import { StringInput } from "@dms/components/DocumentForm/DocumentInputs/StringInput";
import { useIntl } from "react-intl";

export const InvoiceInputBlocks: FC = () => {
    const intl = useIntl();

    return (
        <InvoiceFormItemWrapper title={intl.formatMessage({ id: InvoiceFormBlocksTranslate.INVOICE_DETAILS })}>
            <Row gutter={[10, 0]}>
                <Col span={24}>
                    <Form.Item name={InvoiceInputs.INVOICE_NUMBER}>
                        <DocumentNumberInput
                            suffix={<NumberFieldLabel isLabel={false} />}
                            placeholder={intl.formatMessage({ id: InvoiceFormInputTranslate.INVOICE_NUMBER })}
                            maxLength={36}
                            showCount
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name={InvoiceInputs.CUSTOMER_NUMBER}>
                        <StringInput
                            placeholder={intl.formatMessage({ id: InvoiceFormInputTranslate.CUSTOMER_NUMBER })}
                            disabled={true}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name={InvoiceInputs.DATE}>
                        <DateInput placeholder={intl.formatMessage({ id: InvoiceFormInputTranslate.DATE })} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <DeliverySwitch />
                </Col>
            </Row>
        </InvoiceFormItemWrapper>
    );
};
