import { GQL } from "@binale-tech/shared";
import dayjs from "dayjs";
import React from "react";
import { ALL_DOCUMENTS, DmsTypeOptions, isGroupType, necessaryFieldsForDocumentTypes } from "@dms/configs/constants";
import { AppConfigUtils } from "@dms/scripts/utils/AppConfigUtils";
import {
    DmsDefaultSubType,
    DmsType,
    IDocumentEnriched,
    IDocumentType,
    IInitColumnsType,
    ITableDocument,
    SubTypeActions,
    TBindSubType,
    TFilterConfigItem,
    TTableCols
} from "@dms/types";
import { useGqlMutator } from "../../../scripts/graphql/useGqlMutator";
import {
    bindSubTypeToBank,
    bindSubTypeToKb,
    documentSubTypeCreate,
    documentSubTypeDelete,
    documentSubTypeUpdate,
    unbindSubTypeToBank,
    unbindSubTypeToKb
} from "../../../scripts/context/mutations/documentMutations.graphql";
import { PdfUtils } from "@dms/scripts/utils/PdfUtils";
import { convertPartnerField } from "@dms/scripts/helpers/convertDocumentToInput";

export class DmsUtils {
    static getActiveTypeAndSubType = (activeType: string[]) => {
        const lastIndex = activeType.length - 1;
        const type = activeType[lastIndex] as DmsType | typeof ALL_DOCUMENTS;
        const subType: string | undefined = activeType[lastIndex - 1];
        return { type, subType };
    };

    static getDownloadName = (document: GQL.IDocument) => {
        if (!document) {
            return undefined;
        }
        return document.externalReference ? document.externalReference + ".pdf" : document.fileName;
    };
    static getFilterConfig = (filterConfig: Map<string, TFilterConfigItem>, typeKey: string[]) => {
        return filterConfig.get(typeKey.toString()) || {};
    };
    static getColumnsTableConfig = (columnsTableConfig: Map<string, TTableCols[]>, typeKey: string[]) => {
        return Object.values(columnsTableConfig?.get(typeKey.toString()) ?? {});
    };

    static getGroupId = (type: string, subType: string, documentTypes: IDocumentType[]) => {
        if (type === GQL.IProductKey.Bank) {
            const subTypes = documentTypes.find(v => v.id === DmsType.Bank)?.subTypes ?? [];
            return subTypes.find(v => v.id === subType)?.bankId;
        }
        if (type === GQL.IProductKey.Kb) {
            const subTypes = documentTypes.find(v => v.id === DmsType.KB)?.subTypes ?? [];
            return subTypes.find(v => v.id === subType)?.kbId;
        }
        return undefined;
    };

    static isAccountingExportDisabled = (documents: IDocumentEnriched[], type: string, groupId?: string) => {
        const areDocumentsReady = documents.every(doc => this.getDocumentStatus(doc) === GQL.IDocumentStatus.Ready);
        if (!areDocumentsReady) {
            return true;
        }
        return [GQL.IProductKey.Bank, GQL.IProductKey.Kb].includes(type as GQL.IProductKey) && !groupId;
    };

    static countCalc(documentsArr: IDocumentEnriched[]) {
        const docCount: Map<string, number> = new Map();

        documentsArr
            .filter(el => {
                if (el.type === DmsType.trash || el.type === DmsType.new_documents || el.type === ALL_DOCUMENTS) {
                    return true;
                }
                return this.getDocumentStatus(el) !== GQL.IDocumentStatus.Ready;
            })
            .forEach(doc => {
                const typeCountKey = !isGroupType(doc.type as DmsType) ? doc.type : undefined;

                if (typeCountKey) {
                    const typeCount = typeCountKey ? docCount.get(typeCountKey) : undefined;
                    if (typeCount) {
                        docCount.set(typeCountKey, typeCount + 1);
                    } else {
                        docCount.set(typeCountKey, 1);
                    }
                } else {
                    const nodeCountKey = isGroupType(doc.type as DmsType) ? doc.type : undefined;

                    const subTypeCountKey = doc.subType ? [doc.subType, doc.type].toString() : doc.type;
                    const allSubTypeKey = nodeCountKey
                        ? AppConfigUtils.getDefaultSubTypeItemKey(doc.type, DmsDefaultSubType.all_subTypes)
                        : undefined;
                    const countNoSubTypeKey =
                        nodeCountKey && !doc.subType
                            ? AppConfigUtils.getDefaultSubTypeItemKey(doc.type, DmsDefaultSubType.no_subTypes)
                            : undefined;

                    const nodeCount = nodeCountKey ? docCount.get(nodeCountKey) : undefined;
                    const subTypeCount = subTypeCountKey ? docCount.get(subTypeCountKey) : undefined;
                    const noSubTypeCount = countNoSubTypeKey ? docCount.get(countNoSubTypeKey) : undefined;

                    if (nodeCountKey && allSubTypeKey) {
                        if (nodeCount) {
                            docCount.set(nodeCountKey, nodeCount + 1);
                            docCount.set(allSubTypeKey, nodeCount + 1);
                        } else {
                            docCount.set(nodeCountKey, 1);
                            docCount.set(allSubTypeKey, 1);
                        }
                    }

                    if (subTypeCountKey) {
                        if (subTypeCount) {
                            docCount.set(subTypeCountKey, subTypeCount + 1);
                        } else {
                            docCount.set(subTypeCountKey, 1);
                        }
                    }

                    if (countNoSubTypeKey) {
                        if (noSubTypeCount) {
                            docCount.set(countNoSubTypeKey, noSubTypeCount + 1);
                        } else {
                            docCount.set(countNoSubTypeKey, 1);
                        }
                    }
                }
            });
        return docCount;
    }

    static getDocumentCount = (typeKey: string[], countMap?: Map<string, number>) => {
        if (!countMap) {
            return;
        }

        return countMap.get(typeKey.toString());
    };

    static menuSubTypeAction = async (arg: {
        typeKeyPath: string[];
        action: SubTypeActions;
        companyId: string;
        mutator: ReturnType<typeof useGqlMutator>;
        fetchTypes: () => void;
        setFilterConfig: (typeKey: string[], configData: TFilterConfigItem) => void;
        setColumnsTableConfig: (typeKey: string[], configTableData: Record<keyof TTableCols, boolean>[]) => void;
        deleteTypeConfig: (typeKey: string[]) => void;
        value?: string;
    }) => {
        const {
            typeKeyPath,
            action,
            companyId,
            mutator,
            fetchTypes,
            setColumnsTableConfig,
            setFilterConfig,
            deleteTypeConfig,
            value,
        } = arg;
        const { type, subType } = this.getActiveTypeAndSubType(typeKeyPath);

        switch (action) {
            case SubTypeActions.createSubType:
                if (value) {
                    const input: GQL.IDocumentSubTypeCreateInput = {
                        name: value,
                        companyId,
                        dmsDocumentTypeId: type,
                        icon: undefined,
                    };

                    const res = await mutator.mutate({
                        mutation: documentSubTypeCreate,
                        input,
                        hideMessages: true,
                    });

                    const { documentSubTypeCreate: subTypeCreate } = res;

                    const config = AppConfigUtils.getDefaultConfig(type as keyof IInitColumnsType);

                    setColumnsTableConfig([subTypeCreate.id, ...typeKeyPath], config.defaultColumnsTableConfig);
                    setFilterConfig([subTypeCreate.id, ...typeKeyPath], config.defaultFilterConfig);
                }

                break;
            case SubTypeActions.updateSubType:
                if (typeKeyPath && value) {
                    const input: GQL.IDocumentSubTypeUpdateInput = {
                        companyId,
                        dmsDocumentTypeId: type,
                        id: subType,
                        name: value,
                    };

                    await mutator.mutate({
                        mutation: documentSubTypeUpdate,
                        input,
                        hideMessages: true,
                    });
                }
                break;
            case SubTypeActions.deleteSubType:
                if (typeKeyPath) {
                    const input: GQL.IDocumentSubTypeDeleteInput = {
                        companyId,
                        dmsDocumentTypeId: type,
                        id: subType,
                    };

                    await mutator.mutate({
                        mutation: documentSubTypeDelete,
                        input,
                        hideMessages: true,
                    });

                    deleteTypeConfig(typeKeyPath);
                }
                break;
        }

        fetchTypes();
    };

    static bindSubTypeToItem = async (
        type: TBindSubType,
        input: { subTypeId: string; targetId: string; companyId: string },
        mutator: ReturnType<typeof useGqlMutator>
    ) => {
        if (type === DmsType.Bank) {
            await mutator.mutate({
                mutation: bindSubTypeToBank,
                input,
                hideMessages: true,
            });

            return;
        }

        await mutator.mutate({
            mutation: bindSubTypeToKb,
            input,
            hideMessages: true,
        });
    };

    static unBindSubTypeToItem = async (
        type: TBindSubType,
        input: { subTypeId: string; targetId: string; companyId: string },
        mutator: ReturnType<typeof useGqlMutator>
    ) => {
        if (type === DmsType.KB) {
            await mutator.mutate({
                mutation: unbindSubTypeToKb,
                input,
                hideMessages: true,
            });
            return;
        }

        await mutator.mutate({
            mutation: unbindSubTypeToBank,
            input,
            hideMessages: true,
        });
    };

    static getColKeys = (colsConfig: TTableCols) => {
        const keys = Object.keys(colsConfig) as (keyof TTableCols)[];
        return keys.filter(k => colsConfig[k]);
    };

    static getKeysFromObjects = (array: Record<string, boolean>[]): string[] => {
        if (!array || array.length === 0) {
            return [];
        }
        const keysSet = new Set<string>();
        array.forEach(obj => {
            Object.keys(obj).forEach(key => {
                if (obj[key] === true) {
                    keysSet.add(key);
                }
            });
        });

        return Array.from(keysSet);
    };

    static getTypeAndSubType = (
        value: string,
        documentTypes?: IDocumentType[]
    ): { type?: string; subType?: string } => {
        if (!documentTypes) {
            return {};
        }

        const isType = DmsTypeOptions.find(t => t.value === value);

        if (isType) {
            return { type: value };
        }

        let type: string | undefined;
        const subType = value;

        documentTypes.forEach(documentType => {
            if (documentType.subTypes) {
                documentType.subTypes?.forEach(subtype => {
                    if (subtype.id === subType) {
                        type = subtype.dmsDocumentTypeId;
                    }
                });
            }
        });

        return {
            type,
            subType,
        };
    };

    static isSubtypeHasDocument = (typeKeyPath: string[], documents: IDocumentEnriched[]) => {
        const { subType } = this.getActiveTypeAndSubType(typeKeyPath);
        return documents.findIndex(el => el.subType === subType) >= 0;
    };

    static getPreviewUrl = async (document: IDocumentEnriched): Promise<string> => {
        if (!document.fileUrl) {
            throw "There is no file attached to the document";
        }

        if (document.previewUrl) {
            return document.previewUrl;
        }

        if (new URL(document.fileUrl).pathname.endsWith(".pdf")) {
            const fileUrl: string = document.fileUrl;
            return PdfUtils.previewUrlGenerate(fileUrl);
        }

        return document.fileUrl;
    };

    static formFocus = (
        e: React.KeyboardEvent<HTMLFormElement>,
        keyboardKeys: Set<string>,
        keyType: "up" | "down",
        parentId: string
    ) => {
        if (e.key !== "Enter") {
            return;
        }

        e.preventDefault();

        if (keyType === "down") {
            keyboardKeys.add(e.key);
        } else if (keyType === "up") {
            keyboardKeys.delete(e.key);
        }

        const activeEl = document.activeElement;

        if (!activeEl) {
            return;
        }

        const formEl = document.getElementById(parentId);

        const focusableElements = `button:not([tabIndex="-1"]), input:not([tabIndex="-1"]), textarea:not([tabIndex="-1"])`;
        const inputsArr: HTMLInputElement[] = Array.from(formEl?.querySelectorAll(focusableElements) || []);
        const filteredArr = inputsArr.filter((el: HTMLInputElement) => !el.disabled && el.tabIndex !== -1);

        const index = filteredArr.indexOf(activeEl as HTMLInputElement);
        if (index === -1) {
            return;
        }

        if (keyboardKeys.has("Shift") && keyboardKeys.has("Enter")) {
            const prevIndex = index - 1;
            if (prevIndex >= 0) {
                const prevEl: HTMLInputElement = filteredArr[prevIndex];
                if (prevEl) {
                    prevEl.focus();
                }
            }
            return;
        }

        if (!keyboardKeys.has("Shift") && keyboardKeys.has("Enter")) {
            const nextIndex = index + 1;
            if (nextIndex < filteredArr.length) {
                const nextEl: HTMLInputElement = filteredArr[nextIndex];
                if (nextEl) {
                    nextEl.focus();
                }
            }
        }
    };

    static documentSorter = (
        a: { [x: string]: any },
        b: { [x: string]: any },
        key: string | number,
        type: "string" | "date" | "dateTime" | "number" | "boolean"
    ) => {
        switch (type) {
            case "string": {
                if (a[key] && b[key]) {
                    return a[key].localeCompare(b[key]);
                }
                return (b[key] ? b[key] : " ").localeCompare(a[key] ? a[key] : " ");
            }

            case "date": {
                if (a[key] && b[key]) {
                    return dayjs(a[key], "DD.MM.YYYY").unix() - dayjs(b[key], "DD.MM.YYYY").unix();
                }
                return (b[key] ? b[key] : " ").localeCompare(a[key] ? a[key] : " ");
            }

            case "dateTime": {
                if (a[key] && b[key]) {
                    return dayjs(a[key], "DD.MM.YYYY HH:mm").unix() - dayjs(b[key], "DD.MM.YYYY HH:mm").unix();
                }
                return (b[key] ? b[key] : " ").localeCompare(a[key] ? a[key] : " ");
            }

            case "number": {
                if (a[key] && b[key]) {
                    return +a[key] - +b[key];
                }
                return (b[key] ? b[key].toString() : " ").localeCompare(a[key] ? a[key].toString() : " ");
            }

            case "boolean": {
                const aAttached = a[key] ? 1 : 0;
                const bAttached = b[key] ? 1 : 0;
                return bAttached - aAttached;
            }
        }
    };

    static sortSelectedRowsFirst = (data: ITableDocument[], selectedRowKeys: React.Key[]) => {
        return data.slice().sort((a, b) => {
            const aSelected = selectedRowKeys.includes(a.key);
            const bSelected = selectedRowKeys.includes(b.key);
            if (aSelected && !bSelected) {
                return -1;
            }
            if (!aSelected && bSelected) {
                return 1;
            }
            return 0;
        });
    };

    static getDocumentStatus = (document: IDocumentEnriched): GQL.IDocumentStatus => {
        const docType = document.type as DmsType;
        if (docType === DmsType.new_documents) {
            return GQL.IDocumentStatus.New;
        }
        if (docType === DmsType.trash) {
            return GQL.IDocumentStatus.Trash;
        }
        if (document.aiBackgroundProcessingResult) {
            return GQL.IDocumentStatus.Draft;
        }

        const doc: IDocumentEnriched = { ...document };
        doc.partner = convertPartnerField(document);

        const isAttachment = document.isAttachment;

        const checkFields = necessaryFieldsForDocumentTypes[document.type as DmsType]
            ? necessaryFieldsForDocumentTypes[document.type as DmsType].filter(
                  el => !isAttachment || el !== "documentAmount"
              )
            : null;

        if (!checkFields || !checkFields.length) {
            return GQL.IDocumentStatus.Ready;
        }

        if (checkFields.map(el => doc[el]).every(Boolean)) {
            return GQL.IDocumentStatus.Ready;
        }

        return GQL.IDocumentStatus.Draft;
    };
}
