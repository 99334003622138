import dayjs from "dayjs";
import { TTableCols } from "@dms/types";

const compareStrings = (a: any, b: any) => {
    const stringA = a ? String(a) : "";
    const stringB = b ? String(b) : "";

    if (stringA && stringB) {
        return stringA.localeCompare(stringB);
    }
    return (stringB ? stringB : " ").localeCompare(stringA ? stringA : " ");
};

const compareDates = (a: any, b: any, format: string) => {
    if (a && b) {
        return dayjs(a, format).unix() - dayjs(b, format).unix();
    }
    return (b ? b : " ").localeCompare(a ? a : " ");
};

const compareNumbers = (a: any, b: any) => {
    const aNum = a ? parseFloat(a.toString().replace(/[.,]/g, "")) : null;
    const bNum = b ? parseFloat(b.toString().replace(/[.,]/g, "")) : null;

    return bNum - aNum;
};

const compareBooleans = (a: any, b: any) => {
    const aAttached = a ? 1 : 0;
    const bAttached = b ? 1 : 0;
    return bAttached - aAttached;
};

export const documentSorter = (a: any, b: any, type: "string" | "date" | "dateTime" | "number" | "boolean") => {
    switch (type) {
        case "string":
            return compareStrings(a, b);

        case "date":
            return compareDates(a, b, "DD.MM.YYYY");

        case "dateTime":
            return compareDates(a, b, "DD.MM.YYYY HH:mm");

        case "number":
            return compareNumbers(a, b);

        case "boolean":
            return compareBooleans(a, b);

        default:
            return 0;
    }
};

export const reorderingCols = (
    arr1: Record<keyof TTableCols, boolean>[],
    arr2: Record<keyof TTableCols, boolean>[]
): Record<keyof TTableCols, boolean>[] => {
    const targetArr = [...arr1];
    const sampleArr = [...arr2];
    const sampleDictionary: Record<string, boolean> = {};

    sampleArr.forEach(el => {
        const [key] = Object.keys(el);
        const [value] = Object.values(el);
        sampleDictionary[key] = value;
    });

    const notIncluded = [];

    while (sampleArr.length) {
        const firstSampleEl = sampleArr[0];
        const [key] = Object.keys(firstSampleEl);

        const prevIndex = targetArr.findIndex(el => {
            return key === Object.keys(el)[0];
        });

        if (prevIndex === -1) {
            notIncluded.push(firstSampleEl);
            sampleArr.splice(0, 1);
            delete sampleDictionary[key];
            continue;
        }

        const newIndex = targetArr.findIndex(el => {
            const [targetKey] = Object.keys(el);
            return sampleDictionary[targetKey];
        });

        const [movedElem] = targetArr.splice(newIndex, 1, firstSampleEl);
        targetArr.splice(prevIndex, 1, movedElem);

        sampleArr.splice(0, 1);
        delete sampleDictionary[key];
    }
    return [...notIncluded, ...targetArr];
};
