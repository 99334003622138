import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "antd";
import { Contacts, GQL } from "@binale-tech/shared";
import {
    ControlBlock,
    CustomerInformationBlocks,
    FooterBlock,
    HeaderBlock,
    InvoiceInputBlocks,
    LineItemsBlock
} from "@inv/components/CreateInvoicingForm/components/InvoiceInputBlocks";
import { InvoiceFormContext } from "@inv/modules/CreateInvoicingModule/context/InvoiceFormContext";
import { InvoiceInputs, TInvoicesValues, TResetFormFields } from "@inv/types";
import { ContactsContext } from "scripts/context/ContactsContext";
import { getNames } from "i18n-iso-countries";
import { useIntl } from "react-intl";
import { TOptions } from "@dms/components/DocumentForm/types";
import { InvoicesApi } from "@inv/scripts/api";

export const CreateInvoicingForm = () => {
    const [form] = Form.useForm();
    const { locale } = useIntl();
    const { contacts } = useContext(ContactsContext);
    const { initialValues } = useContext(InvoiceFormContext);
    const dataValue: TOptions = React.useMemo(() => {
        return Object.entries(getNames(locale, { select: "official" })).map(([code, countryName]) => ({
            value: code,
            label: countryName,
        }));
    }, [locale]);
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const values: TInvoicesValues = Form.useWatch([], form);

    const handleFinish = async (inputValues: TInvoicesValues) => {
        setIsLoading(true);
        try {
            await InvoicesApi.invoiceCreate(inputValues);
            setSuccess(true);
        } catch (error) {
            console.error(error);
            setSuccess(false);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        form.resetFields();
        setSuccess(false);
    };

    const autocompleteFormData = (contact: Contacts.Contact): TInvoicesValues => {
        const formData: TInvoicesValues = {
            [InvoiceInputs.CURRENCY_CODE]: values[InvoiceInputs.CURRENCY_CODE],
            [InvoiceInputs.LINE_ITEMS]: values[InvoiceInputs.LINE_ITEMS],
        };

        const contactClient = contact?.relations?.find(r => r.type === GQL.IContactRelation.Client);
        if (contact?.addresses && contact?.addresses.length > 0) {
            const address = contact.addresses[0];
            if (address?.city) {
                formData[InvoiceInputs.CITY] = address.city;
            }
            if (address?.countryCode) {
                formData[InvoiceInputs.COUNTRY_CODE] = address.countryCode;
            }
            if (address?.street) {
                formData[InvoiceInputs.STREET] = address.street;
            }
            if (address?.house) {
                formData[InvoiceInputs.HOUSE_NUMBER] = address.house;
            }
            if (address?.postCode) {
                formData[InvoiceInputs.ZIP_CODE] = address.postCode;
            }
            if (address?.addressLine2) {
                formData[InvoiceInputs.ADDRESS] = address.addressLine2;
            }
        }

        if (contactClient?.internalNumber) {
            formData[InvoiceInputs.CUSTOMER_NUMBER] = contactClient.internalNumber;
        }

        if (contact?.defaultCurrencyCode) {
            formData[InvoiceInputs.CURRENCY_CODE] = contact.defaultCurrencyCode;
        }

        return formData;
    };

    const handleValuesChange = (changedValues: TInvoicesValues) => {
        const contactsField = changedValues[InvoiceInputs.CUSTOMER];
        if (contactsField) {
            const resetFields: TResetFormFields = {
                [InvoiceInputs.CITY]: null,
                [InvoiceInputs.COUNTRY_CODE]: null,
                [InvoiceInputs.STREET]: null,
                [InvoiceInputs.HOUSE_NUMBER]: null,
                [InvoiceInputs.ZIP_CODE]: null,
                [InvoiceInputs.ADDRESS]: null,
                [InvoiceInputs.CUSTOMER_NUMBER]: null,
                [InvoiceInputs.CURRENCY_CODE]: GQL.ICurrencyCode.Eur,
            };
            form.setFieldsValue(resetFields);

            const selectedContact = contacts.find(contact => contact.uuid === contactsField.id);

            if (selectedContact) {
                const autoCompletedData = autocompleteFormData(selectedContact);
                form.setFieldsValue(autoCompletedData);
            }
        }
    };

    useEffect(() => {
        setSuccess(false);
    }, [values]);

    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={initialValues}
            onValuesChange={handleValuesChange}
            onFinish={handleFinish}
            style={{ padding: "20px 0" }}
        >
            <Row gutter={[20, 20]}>
                <Col span={12}>
                    <CustomerInformationBlocks countriesArray={dataValue} />
                </Col>
                <Col span={12}>
                    <InvoiceInputBlocks />
                </Col>
                <Col span={24}>
                    <HeaderBlock />
                </Col>
                <Col span={24}>
                    <LineItemsBlock />
                </Col>
                <Col span={24}>
                    <FooterBlock />
                </Col>
                <ControlBlock success={success} isLoading={isLoading} handleCancel={handleCancel} />
            </Row>
        </Form>
    );
};
