import { GQL } from "@binale-tech/shared";
import { GenericRecord } from "../../models";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { CompanyContext } from "../CompanyContext";
import { useEnrichAccounts } from "./useEnrichAccounts";
import { child, DataSnapshot } from "firebase/database";
import { refTemplates } from "../../api/firebase/firebaseRootRefs";
import {
    RecordBank,
    RecordDeb,
    RecordER,
    RecordERAnzahlung,
    RecordFE,
    RecordKB,
    RecordLA,
    RecordPOS
} from "../../models/GenericRecord";
import { useCollectionListener } from "./useCollectionListener";

const initializers: Map<GQL.IProductKey, (v: any) => GenericRecord> = new Map([
    [GQL.IProductKey.Er, v => new RecordER(v)],
    [GQL.IProductKey.ErA, v => new RecordERAnzahlung(v)],
    [GQL.IProductKey.Deb, v => new RecordDeb(v)],
    [GQL.IProductKey.Fe, v => new RecordFE(v)],
    [GQL.IProductKey.Kb, v => new RecordKB(v)],
    [GQL.IProductKey.Bank, v => new RecordBank(v)],
    [GQL.IProductKey.La, v => new RecordLA(v)],
    [GQL.IProductKey.Pos, v => new RecordPOS(v)],
]);

export const useTemplateData = (pk: GQL.IProductKey, groupId = "default") => {
    const { companyGQL, yearConfig } = React.useContext(CompanyContext);

    const enrichAccounts = useEnrichAccounts();
    const enricherRef = useRef(enrichAccounts);
    useEffect(() => {
        enricherRef.current = enrichAccounts;
    }, [enrichAccounts]);

    const isAccountingKey = initializers.has(pk);
    const shouldSkipLoad = useMemo(
        () => !companyGQL || !yearConfig || !isAccountingKey,
        [companyGQL, isAccountingKey, yearConfig]
    );
    const ref = useMemo(
        () => child(refTemplates, `${companyGQL?.id}/${pk}/${groupId}/${yearConfig?.year}`),
        [companyGQL?.id, groupId, pk, yearConfig?.year]
    );

    const initializer = useCallback(
        (snap: DataSnapshot) => {
            const record = initializers.get(pk)(snap.val());
            return enricherRef.current(record);
        },
        [pk]
    );

    const templates = useCollectionListener(ref, initializer, shouldSkipLoad);

    return useMemo(() => (shouldSkipLoad ? [] : Array.from(templates.values())), [templates, shouldSkipLoad]);
};
