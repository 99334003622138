import React, { FC, useState } from "react";
import { Flex, Form, Select } from "antd";
import { DateInput } from "@dms/components/DocumentForm/DocumentInputs/DateInput";
import { InvoiceFormInputTranslate, InvoiceInputs } from "@inv/types";
import { InvoiceRangePicker } from "@inv/components/CreateInvoicingForm/components/InvoiceRangePicker";
import { useIntl } from "react-intl";

export const DeliverySwitch: FC = () => {
    const [deliveryInputName, setDeliveryInputName] = useState<string>(InvoiceInputs.DELIVERY_DATE);
    const intl = useIntl();

    const deliveryOption = [
        {
            label: intl.formatMessage({ id: InvoiceFormInputTranslate.DELIVERY_DATE }),
            value: InvoiceInputs.DELIVERY_DATE,
        },
        {
            label: intl.formatMessage({ id: InvoiceFormInputTranslate.SERVICE_DATE }),
            value: InvoiceInputs.SERVICE_DATE,
        },
        {
            label: intl.formatMessage({ id: InvoiceFormInputTranslate.SERVICE_PERIOD_DAYS }),
            value: InvoiceInputs.SERVICE_PERIOD_DAYS,
        },
        {
            label: intl.formatMessage({ id: InvoiceFormInputTranslate.SERVICE_PERIOD_MONTHS }),
            value: InvoiceInputs.SERVICE_PERIOD_MONTHS,
        },
        {
            label: intl.formatMessage({ id: InvoiceFormInputTranslate.NO_DELIVERY_DATE }),
            value: "",
        },
    ];

    const switchInput = (type: string) => {
        switch (type) {
            case InvoiceInputs.DELIVERY_DATE:
            case InvoiceInputs.SERVICE_DATE:
                return <DateInput />;
            case InvoiceInputs.SERVICE_PERIOD_DAYS:
                return <InvoiceRangePicker />;
            case InvoiceInputs.SERVICE_PERIOD_MONTHS:
                return <InvoiceRangePicker isMonthPicker={true} />;
            default:
                return null;
        }
    };

    return (
        <Flex align={"center"} gap={10} style={{ marginBottom: 24 }}>
            <Select
                value={deliveryInputName}
                onChange={newValue => setDeliveryInputName(newValue)}
                options={deliveryOption}
            />

            {deliveryInputName ? (
                <Form.Item style={{ width: "100%", marginBottom: 0 }} name={deliveryInputName}>
                    {switchInput(deliveryInputName)}
                </Form.Item>
            ) : null}
        </Flex>
    );
};
